import React, { useState, useMemo } from 'react';
import { ChevronDown, ChevronUp, Check, X, Minus, HelpCircle, Clock } from 'lucide-react';

const features = ['Free', 'CoT', 'Vision', 'Audio', 'File input', 'Code execution'];

const vendors = [
  {
    name: 'OpenAI',
    models: [
      { name: 'GPT-4o', description: 'Great for most tasks', features: { Free: true, CoT: false, Vision: true, Audio: true, 'File input': true, 'Code execution': true } },
      { name: 'GPT-4o mini', description: 'Faster for everyday tasks', features: { Free: true, CoT: false, Vision: false, Audio: false, 'File input': false, 'Code execution': false } },
      { name: 'o1-preview', description: 'Uses advanced reasoning', features: { Free: false, CoT: true, Vision: false, Audio: false, 'File input': false, 'Code execution': false } },
      { name: 'o1-mini', description: 'Faster at reasoning', features: { Free: false, CoT: true, Vision: false, Audio: false, 'File input': false, 'Code execution': false } },
      { name: 'GPT-4', description: '', features: { CoT: false, Vision: false, Audio: false, 'File input': false, 'Code execution': false }, discontinued: true, discontinuedReason: 'Replaced by GPT-4o' },
      { name: 'GPT-3.5', description: '', features: { CoT: false, Vision: false, Audio: false, 'File input': false, 'Code execution': false }, discontinued: true, discontinuedReason: 'Replaced by GPT-4' },
      { name: 'GPT-3', description: '', features: { CoT: false, Vision: false, Audio: false, 'File input': false, 'Code execution': false }, discontinued: true, discontinuedReason: 'Replaced by GPT-3.5' }
    ]
  },
  {
    name: 'Anthropic',
    models: [
      { name: 'Claude 3.5 Sonnet', description: 'Most intelligent model', features: {Free: true, CoT: false, Vision: true, Audio: false, 'File input': true, 'Code execution': true } },
      { name: 'Claude 3 Opus', description: 'Excels at writing and complex tasks', features: {Free: false, CoT: false, Vision: true, Audio: false, 'File input': true, 'Code execution': true } },
      { name: 'Claude 3 Haiku', description: 'Fastest model for daily tasks', features: {Free: false, CoT: false, Vision: true, Audio: false, 'File input': true, 'Code execution': true } }
    ]
  },
  {
    name: 'Google',
    models: [
      { name: 'Gemini 1.5 Flash', description: '', features: {Free: true, CoT: false, Vision: true, Audio: true, 'File input': false, 'Code execution': false } },
      { name: 'Gemini Advanced 1.5 Pro', description: '', features: {Free: false, CoT: false, Vision: true, Audio: true, 'File input': true, 'Code execution': true } }
    ]
  }
];

const FeatureIcon = ({ hasFeature }) => {
  if (hasFeature === true) return <Check className="text-green-500" />;
  if (hasFeature === false) return <X className="text-red-500" />;
  return <Minus className="text-yellow-500" />;
};

const DiscontinuedIcon = ({ reason }) => (
    <div className="relative group inline-flex items-center ml-2">
      <Clock className="text-yellow-500" size={16} />
      <div className="absolute left-full ml-2 p-2 bg-gray-800 text-white text-xs rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap z-10">
        Discontinued: {reason}
      </div>
    </div>
  );
  
  const ExpandedRow = ({ models }) => {
    return (
      <>
        {models.map((model, index) => (
          <tr key={index} className="bg-gray-100 dark:bg-gray-800">
            <td className="px-4 py-2 w-1/4">
              <div className="font-semibold flex items-center">
                {model.name}
                {model.discontinued && <DiscontinuedIcon reason={model.discontinuedReason} />}
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">{model.description}</div>
            </td>
            {features.map((feature) => (
              <td key={feature} className="px-4 py-2 w-1/6">
                <div className="flex justify-center items-center">
                  <FeatureIcon hasFeature={model.features[feature]} />
                </div>
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };

const LLMModelTracker = () => {
  const [expandedVendors, setExpandedVendors] = useState(new Set());

  const sortedVendors = useMemo(() => {
    return [...vendors].sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  const toggleVendor = (index) => {
    setExpandedVendors(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const getVendorFeatureStatus = (vendor, feature) => {
    const featureStatuses = vendor.models.map(model => model.features[feature]);
    if (featureStatuses.every(status => status === true)) return true;
    if (featureStatuses.every(status => status === false)) return false;
    return 'mixed';
  };

  return (
    <div className="container mx-auto p-4">
      <div className="overflow-x-auto">
        <table className="w-full bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100">
          <thead>
            <tr className="bg-gray-200 dark:bg-gray-800">
              <th className="px-4 py-2 text-left w-1/4">Vendor</th>
              {features.map((feature, index) => (
                <th key={feature} className="px-4 py-2 text-center w-1/6">
                  <div className="flex justify-center items-center">
                    {feature}
                    {index === 1 && (
                      <div className="relative group ml-1">
                        <HelpCircle size={16} className="text-gray-500 cursor-help" />
                        <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 p-2 bg-gray-800 text-white text-xs rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap z-10">
                          Chain of Thought. The model's ability to break down complex problems into steps, and explain them.
                        </div>
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedVendors.map((vendor, index) => (
              <React.Fragment key={vendor.name}>
                <tr 
                  className={`cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 ${index % 2 === 0 ? 'bg-gray-50 dark:bg-gray-900' : 'bg-white dark:bg-gray-950'}`}
                  onClick={() => toggleVendor(index)}
                >
                  <td className="px-4 py-2 font-semibold w-1/4">
                    <div className="flex items-center">
                      {expandedVendors.has(index) ? <ChevronUp className="mr-2" /> : <ChevronDown className="mr-2" />}
                      {vendor.name}
                    </div>
                  </td>
                  {features.map((feature) => (
                    <td key={feature} className="px-4 py-2 w-1/6">
                      <div className="flex justify-center items-center">
                        <FeatureIcon 
                          hasFeature={getVendorFeatureStatus(vendor, feature)}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
                {expandedVendors.has(index) && <ExpandedRow models={vendor.models} />}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LLMModelTracker;
